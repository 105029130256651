html {
  box-sizing: border-box;
  font-size: 62.5%; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  color: #000;
  min-height: 100vh;
  overflow-x: hidden; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 3.4rem;
  line-height: 2.25;
  letter-spacing: 0.0147058824em;
  margin: 0; }

a {
  color: inherit;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

:root {
  --black: #000;
  --white: #fff;
  --grey-a: #999;
  --grey-b: #ccc;
  --grey-c: #f0f0f0;
  --gold: #cbad64;
  --red: #b43a3a;
  --grey-metal: #4f4f4f;
  --blue-sky: #a5dcfa;
  --yellow-sun: #fff454;
  --grey-sky: #838383;
  --fabric: #cbbe45;
  --fabric-light: #fffbc9;
  --ocean: #6b8ea2;
  --ocean-light: #cfecfc;
  --light-green: #d8f0e6;
  --light-red: #fbe8e8;
  --light-yellow: #fff7cd;
  --light-blue: #d1eafd;
  --dark-green: #09491d;
  --dark-red: #b60218;
  --dark-yellow: #ffd200;
  --dark-blue: #1b9df7; }

.invisible {
  display: none; }

.product-summary.ui.table {
  border-radius: 0; }

.product-summary .product-image {
  width: 50px; }

.ui.menu {
  border-radius: 0;
  margin: 0; }

.ui.inverted.menu {
  border: 0px solid transparent;
  background: #333333 linear-gradient(rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0));
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(255, 255, 255, 0.15); }

.ui.segment {
  border-radius: 0; }

.ui.inverted.grey.segment {
  background-color: #fff !important; }

.ui.page.modals.dimmer.transition.visible.active {
  display: flex !important; }

.dimmer.inverted {
  background-color: rgba(0, 0, 0, 0.6) !important; }

.download-dropdown {
  margin-right: 0.25em; }

.ui.basic.label {
  background: none #fff !important; }

@media only screen and (max-width: 767px) {
  .ui.container {
    margin: 0 !important; } }
